<template>
  <div class="detail dfc">
    <!-- <icy-header background="transparent" title=""></icy-header> -->
    <div class="detailBg">
      <img v-if="avatar" class="w100 h100 object-cover" :src="avatar" alt="" />
    </div>

    <div class="introduce dfc main-start">
      <div class="namePlateBox dfr main-start cross-center">
        <div v-if="name" class="fs40">{{ name }}</div>
        <div class="name-plate">
          <name-plate :nameplate="this.namePlate"></name-plate>
        </div>
      </div>
      <div class="numBox dfr main-start cross-center">
        <div class="numTitle fs28 fc666">主持场数</div>
        <div v-if="num" class="num fs36">{{ num }}</div>
      </div>
      <div class="intro-box dfr flex-wrap">
        <div class="introBox" v-for="(item, index) in introList" :key="index">
          <div class="intro fs24 fw400 fc666">
            {{ item }}
          </div>
        </div>
      </div>
    </div>

    <div class="detail-title-box">
      <div
        class="detail-title"
        v-for="(item, index) in detailList"
        :key="index"
      >
        <detail-title
          :icon="item.icon"
          :title="item.title"
          :content="item.content"
          :desc="item.desc"
          :scale="item.scale"
        ></detail-title>
      </div>
    </div>

    <div
      style="margin-bottom:0.44rem"
      v-html="detailhtml"
      class="detail-intro fs28 fw400 fc666"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      avatar: "",
      name: "",
      namePlate: "",
      num: "",
      introList: [],
      detailList: [
        {
          icon: require("../assets/images/detail-style.png"),
          title: "主持风格",
          content: ""
        },
        {
          icon: require("../assets/images/detail-enterprise.png"),
          title: "所在企业",
          content: "",
          desc: "",
          scale: ""
        },
        {
          icon: require("../assets/images/detail-position.png"),
          title: "担任职务",
          content: ""
        },
        {
          icon: require("../assets/images/detail-brief.png"),
          title: "个人简介"
        }
      ],
      detailhtml: ``
    };
  },
  created() {
    this.$title("主持人");
    window.scrollTo(0, 0);
    // 获取id
    this.id = parseInt(this.$route.query.id);
    // 调用获取主持人详情信息
    this.getModeratorDetail();
  },
  mounted() {},
  methods: {
    // 获取主持人详情信息
    async getModeratorDetail() {
      const resp = await this.$API
        .get("moderatorDetail", { id: this.id })
        .then(resp => resp);
      if (resp.code == 1000) {
        // 头像
        this.avatar = resp.data.avatar;
        // 姓名
        this.name = resp.data.name;
        // 铭牌等级
        this.namePlate = resp.data.level_name;
        // 主持场数
        this.num = resp.data.total_count;
        // 领域
        resp.data.field.forEach(item => {
          this.introList.push(item.name);
        });
        // 主持风格
        this.detailList[0].content = resp.data.style;
        // 所在企业-公司
        this.detailList[1].content = resp.data.company;
        // 所在企业-行业
        if (resp.data.industry) {
          this.detailList[1].desc = resp.data.industry.name || "";
        }
        // 所在企业-规模
        if (resp.data.scale) {
          this.detailList[1].scale = resp.data.scale.name || "";
        }

        // 担任职务
        this.detailList[2].content = resp.data.job;
        // 个人简介
        let desc = "";
        if (resp.data.description.length) {
          resp.data.description.forEach(item => {
            if (item.type == 0) {
              desc += `<p style='margin: 10px 0; font-size:.28rem; color:#666'> ${item.text} </p>`;
            } else {
              desc += `<img src='${item.image}' style='margin: 10px 0;border-radius: 8px' />  `;
            }
          });
        }

        this.detailhtml = desc;
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.detail {
  width: 7.5rem;
  background-color: #fff;
  .detailBg {
    width: 100%;
    height: 6.24rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .introduce {
    width: 100%;
    margin-top: -0.24rem;
    position: relative;
    background-color: #fff;
    border-radius: 0.24rem 0.24rem 0.12rem 0.12rem;
    .namePlateBox {
      margin-left: 0.36rem;
      margin-top: 0.36rem;
      .name-plate {
        margin-left: 0.16rem;
      }
    }
    .numBox {
      margin-left: 0.36rem;
      .numTitle {
        margin-top: 0.2rem;
      }
      .num {
        color: #cb9025;
        margin-top: 0.155rem;
        margin-left: 0.12rem;
      }
    }
    .intro-box {
      margin-top: 0.28rem;
      margin-left: 0.36rem;
      margin-right: 0.36rem;
      .introBox {
        background: #f6f6f6;
        border-radius: 0.04rem;
        margin-right: 0.12rem;
        margin-top: 0.12rem;
        .intro {
          padding: 0.12rem 0.18rem;
        }
      }
    }
  }
  .detail-title-box {
    margin-top: 0.8rem;
    margin-left: 0.36rem;
    .detail-title:not(:first-child) {
      margin-top: 0.6rem;
    }
  }

  .detail-intro {
    width: 6.26rem;
    margin-left: 0.88rem;
  }
}
</style>
